<template>
	<Toast />
	<input v-show='false' title='打开要插入的图片 open the insert image file' accept=".jpg,.jpeg,.png,.PNG,.JPG,.JPEG"
		id='image_insert' multiple="multiple" type="file" />
	<iframe v-show='false' ref='save_file' src="saveFile/saveFile.html" frameborder="0"
		style="width: 100%;height:100px;"></iframe>
	<input hidden="true" title='打开要导入文件' class="newFile" id='choose_file' type="file" />
	<Sidebar v-model:visible="visibleTop" style="height: auto;" :modal="false" :baseZIndex="1000" position="top">
		<div class="grid formgrid">
			<div class="col-12 lg:col-6">
				<div class="p-inputgroup">
					<Button icon="pi pi-plus" @click='addNewAttribute' label="添加自定义属性" />
					<InputText @input="newAttrInput" v-model='new_attribute' v-on:keyup.enter="addNewAttribute"
						placeholder="自定义属性" />
				</div>
			</div>
			<div class="col-12 lg:col-2">
				<Button icon="pi pi-times" class="p-button-danger mr-2 mb-2" @click='visibleTop=false' label="保存编辑" />
			</div>
		</div>

		<div class="grid formgrid"
			style="margin-top:15px;padding: 10px 0px 10px 0px;background-color: #ededed;font-weight: 700;border-radius: 3px;">
			<div v-for="(item, index) of config_title_items" :key="index"
				:class="index==0?'col-12 lg:col-4':'col-12 lg:col-2'" :title="item['title']">
				<p>{{item['placeholder']}}</p>
			</div>
		</div>
		<div v-if='picklistValue'
			style="background-color: aliceblue;height: 300px;overflow-y: auto;padding: 10px;width: 100%;">
			<div v-for="(item, index) of picklistValue[editing_config_index]" :key="index">
				<!-- <InputText placeholder="Keyword"/> -->
				<div class="grid formgrid" style="margin-top: 5px;">
					<div v-for="(c_item, c_index) of config_title_items" :key="c_index"
						:class="c_index==0?'col-12 lg:col-4':'col-12 lg:col-2'" :title="c_item['title']">
						<!-- 					<div v-for="(c_item, c_index) of config_title_items" :key="c_index"
						:class="c_index==0?'col-12 lg:col-4':'col-12 lg:col-2'" :title="c_item['title']"> -->
						<!-- <p>{{item['placeholder']}}</p> -->
						<div v-if='c_index==0' class="p-inputgroup">
							<span class="p-inputgroup-addon">
								<i>{{index}}</i>
							</span>
							<InputText style="width:100%;" :placeholder="c_item['placeholder']"
								v-model="item[c_item['title_name']]" />
						</div>
						<div v-if='c_index!=0' class="p-inputgroup">
							<InputText style="width:100%;" :placeholder="c_item['placeholder']"
								v-model="item[c_item['title_name']]" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</Sidebar>
	<div class="card grid p-fluid" style="padding: 0px;margin-bottom: -40px;">
		<div class="col-12 md:col-7">
			<!-- <div v-if='images.length>0'> -->
			<!-- <div class="grid" style="position: fixed;z-index: 100;padding: 10px 0px 0px 7px;"> -->
			<div class="grid formgrid">
				<div class="col-12 mb-2 lg:col-3 lg:mb-0">
					<Button label="选择图片" title='打开需要添加水印的图片' @click='selectImages' icon="pi pi-images"
						class="p-button-success mr-2 mb-2" />
				</div>
				<div class="col-12 mb-3 lg:col-3 lg:mb-0">
					<Button title='保存并下载所有图片(图片保存后会清除 exif 信息)' label="保存图片" @click='saveWaterMarkImages'
						icon="pi pi-arrow-circle-down" class="p-button-info mr-2 mb-2" />
				</div>
				<div class="col-12 mb-2 lg:col-2 lg:mb-0">
					<h5 class='single-item' title='图片导出可能过大,建议压缩'>压缩比例</h5>
				</div>
				<div class="col-12 mb-2 lg:col-3 lg:mb-0" title='图片导出可能过大,建议压缩'>
					<InputText style="width: 100%;" v-model.number="compress_scale" />
					<Slider :min='0' :step="0.1" :max="100" v-model="compress_scale" />
				</div>
			</div>
			<div :style="all_image_style" style="overflow: scroll;">
				<Galleria v-if='images.length>0' :value="images" :showIndicators='true' thumbnailsPosition='top'
					:responsiveOptions="galleriaResponsiveOptions" :numVisible="6" :circular="true"
					containerStyle="max-width: 1200px; margin: auto;margin-top:0px;">
					<template #item="slotProps">
						<div style="background-color:azure;height: 100%;">
							<span v-if='vertical_align_start=="top"' id='image_water_mark'
								:style="water_mark_style">{{slotProps.item.water_mark}}</span>
							<img @load="changeImageItem(slotProps.item.index)" id='image_container'
								:src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt"
								style="width:100%;background-size: contain;display: block;margin-bottom: 40px;margin-top: 40px;" />
							<span v-if='vertical_align_start=="bottom"' id='image_water_mark'
								:style="water_mark_style">{{slotProps.item.water_mark}}</span>
							<!-- <span :style="water_mark_style_back_up">{{slotProps.item.water_mark}}</span>					 -->
						</div>
					</template>
					<template #thumbnail="slotProps">
						<img :src="slotProps.item.itemImageSrc"
							style="width: auto;height: 60px;background-size: contain;display: block;"
							:alt="slotProps.item.alt" />
					</template>
				</Galleria>
			</div>
		</div>
		<div :style="all_style" class="col-12 md:col-5" style="overflow: auto;border-left: 5px solid aliceblue;">
			<TabView>
				<TabPanel header="水印文字">
					<div class="grid formgrid config-group" style="margin-top: -5px;">
						<div class="col-12 mb-2 lg:col-3 lg:mb-0">
							<h5 class='single-item'>水印文字</h5>
						</div>
						<div class="col-12 mb-2 lg:col-6 lg:mb-0">
							<div class="p-inputgroup">
								<Button label="分隔符" />
								<InputText type="text" v-model="split_char" placeholder="分隔符">
								</InputText>
							</div>
						</div>
						<div class="col-12 mb-2 lg:col-3 lg:mb-0" title='参数不存在时用默认值代替,默认值不存在时用字段名称代替'>
							<div class="field-checkbox mb-0 single-item">
								<Checkbox id="checkOption2" name="option" value="1" v-model="use_default" />
								<label for="checkOption2">字段名</label>
							</div>
						</div>
						<div title='可手动输入修改水印文字' class="col-12 mb-2 lg:col-12 lg:mb-0">
							<Textarea @input='modifyWaterMark' style="width: 100%;" v-model='current_water_mark'
								placeholder="Your Message" :autoResize="true" rows="3" cols="30" />
						</div>
						<div title='设定字段之间的间隔' class="col-12 mb-2 lg:col-3 lg:mb-0">
							<label>间隔大小</label>
							<InputText style="width: 100%;" v-model.number="white_space_number" />
							<Slider :min='0' :max="10" v-model="white_space_number" />
						</div>
						<div title='设定数字最多保留的小数位' class="col-12 mb-2 lg:col-3 lg:mb-0">
							<label>小数位</label>
							<InputText style="width: 100%;" v-model.number="max_decimal_places" />
							<Slider :min='0' :max="64" v-model="max_decimal_places" />
						</div>
						<div class="col-12 mb-2 lg:col-3 lg:mb-0">
							<label>字重</label>
							<InputText style="width: 100%;" v-model.number="font_weight" />
							<Slider :min='100' :step="100" :max="900" v-model="font_weight" />
						</div>
						<div class="col-12 mb-2 lg:col-3 lg:mb-0">
							<label>字体大小</label>
							<InputText style="width: 100%;" v-model.number="text_font_size" />
							<Slider :min='0' :step="0.1" :max="100" v-model="text_font_size" />
						</div>
					</div>
					<div class="grid formgrid config-group" style="margin-top: 10px;padding-bottom: 0px;">
						<div class="col-12 lg:col-3 lg:mb-0">
							<h5 class='single-item'>水印项目</h5>
						</div>
						<div class="col-12 lg:col-5" title='新增自定义项目/修改名称/修改默认值等'>
							<Button label="编辑已选项" @click='editCameraConfig(1)' icon="pi pi-pencil"
								class="p-button-info mr-2 mb-2" />
						</div>
						<div class="col-12 lg:col-4" title='新增自定义项目/修改名称/修改默认值等'>
							<Button label="编辑未选项" @click='editCameraConfig(0)' icon="pi pi-pencil"
								class="p-button-secondary mr-2 mb-2" />
						</div>
					</div>
					<div class="grid formgrid config-group" style="margin-top: 10px;">
						<PickList v-model="picklistValue" class="col-12 lg:col-12" dataKey="code">
							<template #sourceHeader>
								From
							</template>
							<template #targetHeader>
								To
							</template>
							<template #item="slotProps">
								<div :title='slotProps.item.chinese_name'>
									{{slotProps.item.chinese_name}}
								</div>
								<div>
									<p>{{slotProps.item.default_value}}</p>
								</div>
							</template>
						</PickList>
					</div>
				</TabPanel>
				<TabPanel header="水印位置">
					<div class="grid config-group">
						<div class="col-12 mb-2 lg:col-3 lg:mb-0">
							<label>水平距离</label>
							<InputText style="width: 100%;" v-model.number="text_left" />
							<Slider :min='-100' :step="0.1" :max="100" v-model="text_left" />
						</div>
						<div class="col-12 mb-2 lg:col-9 lg:mb-0">
							<label>垂直距离</label>
							<InputText style="width: 100%;" v-model.number="text_top" />
							<Slider :min='-2200' :step="0.1" :max="2200" v-model="text_top" />
						</div>
						<div class="col-12 md:col-3">
							<h5>水平对齐</h5>
						</div>
						<div class="col-12 md:col-3">
							<div class="field-radiobutton mb-0">
								<RadioButton id="option1" name="option" value="center" v-model="horizontal_align" />
								<label for="option1">居中</label>
							</div>
						</div>
						<div class="col-12 md:col-3">
							<div class="field-radiobutton mb-0">
								<RadioButton id="option2" name="option" value="left" v-model="horizontal_align" />
								<label for="option2">左对齐</label>
							</div>
						</div>
						<div class="col-12 md:col-3">
							<div class="field-radiobutton mb-0">
								<RadioButton id="option3" name="option" value="right" v-model="horizontal_align" />
								<label for="option3">右对齐</label>
							</div>
						</div>
						<div class="col-12 md:col-3">
							<h5>垂直对齐</h5>
						</div>
						<div class="col-12 md:col-3">
							<div class="field-radiobutton mb-0">
								<RadioButton id="option1" name="option" value="top" v-model="vertical_align_start" />
								<label for="option1">上方</label>
							</div>
						</div>
						<div class="col-12 md:col-3">
							<div class="field-radiobutton mb-0">
								<RadioButton id="option2" name="option" value="bottom" v-model="vertical_align_start" />
								<label for="option2">下方</label>
							</div>
						</div>
						<div class="col-12 md:col-12">
							<Message style="margin-top: 0px;margin-bottom: 0px;" :severity="'warn'"><span
									style="font-weight: 800;">水印位置在下方时请选择“下方”，水印在上方请选择“上方”，否则无法保证不同的照片中水印处于同样的位置</span>
							</Message>
						</div>
					</div>
				</TabPanel>
				<TabPanel header="水印样式">
					<div class="grid formgrid config-group" style="margin-top: -5px;">
						<div style="width: 100%;height: 10px;"></div>
						<div class="col-12 mb-2 lg:col-6 lg:mb-0">
							<label>文字不透明度</label>
							<InputText style="width: 100%;" v-model.number="text_opacity" />
							<Slider :min='0' :step="0.1" :max="100" v-model="text_opacity" />
						</div>
						<div class="col-12 mb-2 lg:col-6 lg:mb-0">
							<label>背景不透明度</label>
							<InputText style="width: 100%;" v-model.number="background_opacity" />
							<Slider :min='0' :step="0.1" :max="100" v-model="background_opacity" />
						</div>
					</div>
					<div class="grid formgrid config-group" style="margin-top: 10px;">
						<div class="col-12 mb-2 lg:col-3 lg:mb-0">
							<label>水印颜色</label>
						</div>
						<div class="col-12 mb-2 lg:col-4 lg:mb-0">
							<div @click="clickColorInput('text_color')" title="点击色块修改水印文字颜色"><input type="color"
									id="text_color"><label>文字颜色</label></div>
						</div>
						<div class="col-12 mb-2 lg:col-5 lg:mb-0">
							<div @click="clickColorInput('background_color')" title="点击色块修改水印背景颜色">
								<input type="color" id="background_color"><label>背景颜色</label>
							</div>
						</div>
						<div style="width: 100%;height: 10px;"></div>
						<div class="col-12 mb-2 lg:col-3 lg:mb-0">
							<label>背景设置</label>
						</div>
						<div class="col-12 mb-2 lg:col-4 lg:mb-0" title='保存时仅有文字的区域显示背景色'>
							<div class="field-checkbox mb-0">
								<Checkbox style='maring-left:20px;' id="checkOption2" name="option" value="1"
									v-model="only_text_background" />
								<label for="checkOption2">仅文字背景</label>
							</div>
						</div>
						<div class="col-12 mb-2 lg:col-5 lg:mb-0" title='保存时仅有文字的部分显示背景色'>
							<div class="field-checkbox mb-0">
								<Checkbox style='maring-left:20px;' id="checkOption2" name="option" value="1"
									v-model="save_background" />
								<label for="checkOption2">水印背景色</label>
							</div>
						</div>
					</div>
					<div class="grid formgrid config-group" style="margin-top: 10px;">
						<div class="col-12 mb-2 lg:col-6 lg:mb-0">
							<h5 class='single-item' style='margin-bottom: -10px;' title='点击右侧条目选择字体'>当前字体</h5>
							<h5 title='点击右侧条目选择字体' :style="'font-family:'+text_font.name+';'" class='single-item'>
								{{text_font.name}}
							</h5>
							<div class="field-checkbox mb-0 single-item">
								<Checkbox style='maring-left:20px;' id="checkOption2" name="option" value="1"
									v-model="font_style" />
								<label for="checkOption2">启用斜体</label>
							</div>
							<Button style="margin-top: 10px;" title='按照字体列表样式输入新字体后上传就可以更新字体' label="下载字体列表"
								icon="pi pi-arrow-circle-down" @click="outputFontList" iconPos="left"
								class="p-button-secondary mr-2 mb-2"></Button>
							<Button title='上传后字体列表文件后更新字体,如不了解字体列表样式请先"下载字体列表"后根据列表修改' label="上传字体列表"
								icon="pi pi-arrow-circle-up" @click="openFile" iconPos="left"
								class="p-button-info mr-2 mb-2"></Button>
						</div>
						<div class="col-12 mb-2 lg:col-6 lg:mb-0"
							style="height: 270px;overflow: auto;background-color:aliceblue;padding: 10px;border-radius: 5px;">
							<div v-for="(item, index) of fonts_value" :key="index" :title="item.name">
								<p @click="setFont(item)"
									:style="'font-size:18px;pading:3px;width:100%;font-family:'+item.name+';'">
									{{item.name}}
								</p>
							</div>
						</div>
					</div>
				</TabPanel>
				<TabPanel header="加入交流群">
					<div class="grid formgrid config-group"
						style="display: flex;flex-direction: row;justify-content: center;">
						<h5 class='single-item' style="width: 100%;">欢迎入群交流水印配置、照片、拍摄经验</h5>
						<img src="images/weixin_group.jpg" style="max-width: 300px;" />
					</div>
					<div class="grid formgrid config-group" style="margin-top: 10px;">
						<div class="col-12 mb-2 lg:col-12 lg:mb-0">
							<h5 class='single-item'>邮件联系</h5>
							<Textarea style="width: 100%;" value='群二维码失效或建议反馈欢迎发送邮件至：exifprinter@163.com'
								placeholder="Your Message" :autoResize="true" rows="3" cols="30" />
						</div>
					</div>
				</TabPanel>
				<TabPanel header="必读说明">
					<Message style="margin-top: 10px;margin-bottom: 20px;" :severity="'warn'">
						<p style="font-weight: 800;">照片exif信息被抹除导致无法读取的可能原因：</p>
						<p style="font-weight: 800;">①照片经过微信直接传输；</p>
						<p style="font-weight: 800;">②照片由iPhone拍摄；</p>
						<p style="font-weight: 800;">③照片经多个软件处理过；</p>
					</Message>
					<Message style="margin-top: 20px;margin-bottom: 10px;" :severity="'success'">
						<p style="font-weight: 800;">保留照片exif信息的方法：</p>
						<p style="font-weight: 800;">①微信传照片时以压缩包的形式传输；</p>
						<p style="font-weight: 800;">②某些iPhone的照片带有exif信息；</p>
						<p style="font-weight: 800;">③尽量使用不会抹除exif信息的软件处理照片；</p>
						<p style="font-weight: 800;">④任何疑问或软件求推荐欢迎加入交流群讨论；</p>
					</Message>
				</TabPanel>
			</TabView>
		</div>
	</div>

</template>

<script>
	//从相对路径引入 NetWorkService.js
	import ProductService from "../service/ProductService";
	import PhotoService from "../service/PhotoService";
	import ReadFileService from '../service/ReadFileService.js';
	// import saveAs from '../service/FileSaver.js';
	import saveAs from 'file-saver';
	import ExifReader from 'exifreader';
	export default {
		name: '',
		components: {

		},
		data() {
			return {
				all_image_style: {
					"height": "auto",
					// "width": "100%",
					"overflow": "scroll",
				},
				all_style: {
					"height": "auto",
					// "width": "100%",
					"overflow": "scroll",
				},
				config_title_items: [{
						"col_number": 4,
						"title": "属性名称，对应照片 exif 信息中属性",
						"title_name": "tag_name",
						"placeholder": "属性名",
					},
					{
						"col_number": 2,
						"title": "翻译名称，显示于项目选择时",
						"title_name": "chinese_name",
						"placeholder": "翻译",
					},
					{
						"col_number": 2,
						"title": "默认值，exif 信息缺省时显示该值",
						"title_name": "default_value",
						"placeholder": "默认值",
					},
					{
						"col_number": 2,
						"title": "前缀，附加显示于 exif 信息前",
						"title_name": "prefix",
						"placeholder": "前缀",
					},
					{
						"col_number": 2,
						"title": "后缀，附加显示于 exif 信息后",
						"title_name": "suffix",
						"placeholder": "后缀",
					}
				],
				water_mark_style: {
					"white-space": "nowrap",
					"width": "100%",
					"background-color": "rgba(255,255,255,0.7)",
					"color": "rgba(0,0,0,1)",
					"position": "absolute",
					"z-index": 100,
					"margin-top": "10px",
					"left": '0%',
					"font-family": "Georgia",
					"font-weight": 500,
					"font-style": "oblique",
					"font-size": "15px",
					"text-align": "center",
					"padding": "2px",
				},
				water_mark_style_back_up: {
					"white-space": "nowrap",
					"width": "100%",
					"height": "15px",
					"background-color": "rgba(255,255,0,0.7)",
					"color": "rgba(0,0,0,0)",
					"position": "absolute",
					"z-index": 10,
					"margin-top": "10px",
					"left": '0%',
					"font-family": "Georgia",
					"font-weight": 500,
					"font-size": "15px",
					"text-align": "center",
					"padding": "2px",
				},
				text_font: {
					name: "Georgia",
					code: 'GE'
				},
				max_decimal_places:6,
				fonts_value: [{
					"name": "Microsoft YaHei",
					"code": "Microsoft YaHei"
				}, {
					"name": "Times New Roman",
					"code": "Times New Roman"
				}, {
					"name": "Georgia",
					"code": "Georgia"
				}, {
					"name": "隶书",
					"code": "隶书"
				}, {
					"name": "华文仿宋",
					"code": "华文仿宋"
				}, {
					"name": "华文琥珀",
					"code": "华文琥珀"
				}, {
					"name": "华文楷体",
					"code": "华文楷体"
				}, {
					"name": "华文隶书",
					"code": "华文隶书"
				}, {
					"name": "华文宋体",
					"code": "华文宋体"
				}, {
					"name": "华文细黑",
					"code": "华文细黑"
				}, {
					"name": "华文行楷",
					"code": "华文行楷"
				}, {
					"name": "华文新魏",
					"code": "华文新魏"
				}, {
					"name": "方正舒体",
					"code": "方正舒体"
				}, {
					"name": "方正姚体",
					"code": "方正姚体"
				}, {
					"name": "幼圆",
					"code": "幼圆"
				}, {
					"name": "华文中宋",
					"code": "华文中宋"
				}, {
					"name": "华文彩云",
					"code": "华文彩云"
				}, {
					"name": "Bauhaus 93",
					"code": "Bauhaus 93"
				}, {
					"name": "Algerian",
					"code": "Algerian"
				}, {
					"name": "Bell MT",
					"code": "Bell MT"
				}, {
					"name": "Book Antiqua",
					"code": "Book Antiqua"
				}, {
					"name": "Broadway",
					"code": "Broadway"
				}, {
					"name": "Calibri",
					"code": "Calibri"
				}, {
					"name": "Centaur",
					"code": "Centaur"
				}, {
					"name": "Century",
					"code": "Century"
				}, {
					"name": "Chiller",
					"code": "Chiller"
				}, {
					"name": "Colonna MT",
					"code": "Colonna MT"
				}, {
					"name": "Colonna M",
					"code": "Colonna M"
				}, {
					"name": "Comic Sans MS",
					"code": "Comic Sans MS"
				}, {
					"name": "Comic Sans MS Bold",
					"code": "Comic Sans MS Bold"
				}, {
					"name": "Comic Sans MS Italic",
					"code": "Comic Sans MS Italic"
				}, {
					"name": "Cooper",
					"code": "Cooper"
				}, {
					"name": "Freestyle Script",
					"code": "Freestyle Script"
				}, {
					"name": "Footlight MT Light",
					"code": "Footlight MT Light"
				}, {
					"name": "Gabriola",
					"code": "Gabriola"
				}, {
					"name": "Gadugi",
					"code": "Gadugi"
				}, {
					"name": "Harrington",
					"code": "Harrington"
				}, {
					"name": "Microsoft Himalaya",
					"code": "Microsoft Himalaya"
				}, {
					"name": "HoloLens MDL2 Assets",
					"code": "HoloLens MDL2 Assets"
				}, {
					"name": "High Tower Text",
					"code": "High Tower Text"
				}, {
					"name": "High Tower Text Italic",
					"code": "High Tower Text Italic"
				}, {
					"name": "Impact",
					"code": "Impact"
				}, {
					"name": "Informal Roman",
					"code": "Informal Roman"
				}, {
					"name": "Ink Free",
					"code": "Ink Free"
				}, {
					"name": "Kristen ITC",
					"code": "Kristen ITC"
				}, {
					"name": "Javanese Text",
					"code": "Javanese Text"
				}, {
					"name": "Jokerman",
					"code": "Jokerman"
				}, {
					"name": "Juice ITC",
					"code": "Juice ITC"
				}, {
					"name": "Kunstler Script",
					"code": "Kunstler Script"
				}, {
					"name": "Wide Latin",
					"code": "Wide Latin"
				}, {
					"name": "Vladimir Script",
					"code": "Vladimir Script"
				}, {
					"name": "Vivaldi Italic",
					"code": "Vivaldi Italic"
				}, {
					"name": "Magneto Bold",
					"code": "Magneto Bold"
				}, {
					"name": "MV Boli",
					"code": "MV Boli"
				}, {
					"name": "Niagara Engraved",
					"code": "Niagara Engraved"
				}, {
					"name": "Niagara Solid",
					"code": "Niagara Solid"
				}, {
					"name": "Playbill",
					"code": "Playbill"
				}, {
					"name": "Ravie",
					"code": "Ravie"
				}, {
					"name": "Segoe Print",
					"code": "Segoe Print"
				}, {
					"name": "Snap ITC",
					"code": "Snap ITC"
				}, {
					"name": "Stencil",
					"code": "Stencil"
				}],
				new_attribute: '',
				font_weight: 600,
				font_style: [], // normal italic
				editing_config_index: 1,
				compress_scale: 80,
				visibleTop: false,
				vertical_align_start: 'top',
				only_text_background: [],
				save_background: ['1'],
				use_default: ['1'],
				horizontal_align: 'center',
				text_color: '#000000',
				background_color: '#ffffff',
				text_opacity: 100,
				background_opacity: 100,
				text_top: 40,
				text_left: 0,
				text_font_size: 15,
				white_space_number: 3,
				current_water_mark: '当前水印',
				current_image_index: 0,
				true_text_top: 40,
				imags_data: [],
				split_char: '|',
				calendarValue: null,
				picklistValue: null,
				// picklistValue:null,
				en_camera_settings: [],
				zh_cn_camera_settings: [],
				images: [],
				galleriaResponsiveOptions: [{
						breakpoint: "1024px",
						numVisible: 5,
					},
					{
						breakpoint: "960px",
						numVisible: 4,
					},
					{
						breakpoint: "768px",
						numVisible: 3,
					},
					{
						breakpoint: "560px",
						numVisible: 1,
					},
				],
				carouselResponsiveOptions: [{
						breakpoint: "1024px",
						numVisible: 3,
						numScroll: 3,
					},
					{
						breakpoint: "768px",
						numVisible: 2,
						numScroll: 2,
					},
					{
						breakpoint: "560px",
						numVisible: 1,
						numScroll: 1,
					},
				],
			}
		},
		watch: {
			picklistValue: {
				handler(newValue, oldValue) {
					// var new_from=newValue[0];
					if (oldValue == null || newValue == null) {
						return;
					}

					var new_to = newValue[1];
					// var old_from=oldValue[0];
					var old_to = oldValue[1];

					if (old_to && new_to && old_to.length > new_to.length) {
						// console.log('从左往右变化了');
						var new_from = newValue[0];
						var last_add = new_from[new_from.length - 1];
						this.picklistValue[0].pop();
						this.picklistValue[0].unshift(last_add);
						// console.log('最后添加的元素',last_add);
					}
					this.flushAllWaterMark();
				},
				deep: true,
				immediate: true,
			},
			white_space_number: {
				handler(newValue) {
					this.white_space_number = newValue;
					this.flushAllWaterMark();
				},
				deep: true,
				immediate: true,
			},
			max_decimal_places:{
				handler(newValue) {
					this.max_decimal_places = newValue;
					this.flushAllWaterMark();
				},
				deep: true,
				immediate: true,
			},
			split_char: {
				handler() {
					//刷新水印文字
					this.flushAllWaterMark();
				},
				deep: true,
				immediate: true,
			},
			use_default: {
				handler() {
					this.flushAllWaterMark();
				},
				deep: true,
				immediate: true,
			},
			text_top: {
				handler(newValue) {
					// console.log('新上边距',newValue);
					this.text_top = newValue;
					this.true_text_top = newValue;
					// if (this.vertical_align_start == 'top') {
					// 	this.true_text_top = newValue + 40;
					// } else {
					// 	this.true_text_top = newValue - 40;
					// }
					this.saveConfigToLoalItem();
				},
				deep: true,
				immediate: true,
			},
			text_left: {
				handler(newValue) {
					this.text_left = newValue;
					this.saveConfigToLoalItem();
				},
				deep: true,
				immediate: true,
			},
			text_font_size: {
				handler(newValue) {
					this.text_font_size = newValue;
					this.saveConfigToLoalItem();
				},
				deep: true,
				immediate: true,
			},
			horizontal_align: {
				handler() {
					this.saveConfigToLoalItem();
				},
				deep: true,
				immediate: true,
			},
			vertical_align_start: {
				handler() {
					this.saveConfigToLoalItem();
					// console.log('对齐方式',newValue);
					// if (newValue == 'top') {
					// 	this.true_text_top = this.text_top + 50;
					// } else {
					// 	this.true_text_top = this.text_top - 50;
					// }
				},
				deep: true,
				immediate: true,
			},
			text_font: {
				handler() {
					this.saveConfigToLoalItem();
				},
				deep: true,
				immediate: true,
			},
			text_color: {
				handler() {
					this.saveConfigToLoalItem();
				},
				deep: true,
				immediate: true,
			},
			background_color: {
				handler() {
					this.saveConfigToLoalItem();
				},
				deep: true,
				immediate: true,
			},
			text_opacity: {
				handler(newValue) {
					this.text_opacity = newValue;
					this.saveConfigToLoalItem();
				},
				deep: true,
				immediate: true,
			},
			background_opacity: {
				handler(newValue) {
					this.background_opacity = newValue;
					this.saveConfigToLoalItem();
				},
				deep: true,
				immediate: true,
			},
			save_background: {
				handler() {
					this.saveConfigToLoalItem();
				},
				deep: true,
				immediate: true,
			},
			only_text_background: {
				handler() {
					this.saveConfigToLoalItem();
				},
				deep: true,
				immediate: true,
			},
			compress_scale: {
				handler(newValue) {
					this.compress_scale = newValue;
					this.saveConfigToLoalItem();
				},
				deep: true,
				immediate: true,
			},
			font_weight: {
				handler(newValue) {
					this.font_weight = newValue;
					this.saveConfigToLoalItem();
				},
				deep: true,
				immediate: true,
			},
			font_style: {
				handler() {
					this.saveConfigToLoalItem();
				},
				deep: true,
				immediate: true,
			},
		},
		// networkService: null,
		created() {
			this.productService = new ProductService();
			this.photoService = new PhotoService();
			this.ReadFileService = new ReadFileService();
		},
		mounted() {
			// window.addAds();
			// window.addAds();
			let vm = this;
			vm.openFileListen();
			vm.resizeWin();
			// vm.testExifReader();
			//监听选择文件
			var inputElement = document.getElementById("image_insert");
			inputElement.onchange = function(ev) {
				// const file = ev.target.files[0];
				// readExif(file);
				// console.log('选中文件', ev.target.files);
				// vm.pureReadExif(ev.target.files);
				if (ev.target.files && ev.target.files.length > 0) {
					// vm.images=[];
					vm.createImageUrls(ev.target.files);
				}
			};
			this.observeValue('text_color');
			this.observeValue('background_color');
			// this.ReadFileService.getEnglishCameraSettings().then(data => {
			// 	// console.log('英文配置', data);
			// 	this.en_camera_settings = data;
			// });
			this.saveConfigToLoalItem();
			this.readConfigFormLocal();
			this.photoService.getImages().then((images) => {
				this.images = images;
				this.flushAllWaterMark();
			});
		},
		methods: {
			clickColorInput(node_id) {
				var this_input = document.getElementById(node_id);
				this_input.click();
			},
			editCameraConfig(select_index) {
				this.editing_config_index = select_index;
				this.visibleTop = true;
			},
			setFont(item) {
				this.text_font = item;
			},
			addNewAttribute() {
				if (this.new_attribute == '') {
					alert('无法添加空属性！')
					return;
				}
				// v-on:keyup.enter="searchWord"
				var this_time = new Date().getTime();
				var all_number = this.picklistValue[0].length + this.picklistValue[1].length + 1;
				var new_attr = {
					"tag_name": this.new_attribute,
					"english_name": "New_Attribute",
					"chinese_name": "新属性",
					"code": this_time + '_' + all_number,
					"value": "",
					"default_value": "",
					"prefix": "",
					"suffix": ""
				};
				this.picklistValue[this.editing_config_index].unshift(new_attr);
			},
			rgbaToHexAndOpacity(rgba) {
				var rgbaArray = rgba.replace(/[^\d,.]/g, '').split(',');
				var r = parseInt(rgbaArray[0]);
				var g = parseInt(rgbaArray[1]);
				var b = parseInt(rgbaArray[2]);
				var a = parseFloat(rgbaArray[3]);
				var hex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
				return {
					hex: hex,
					opacity: a
				};
			},
			hexToRgba(hex, opacity) {
				hex = hex.replace('#', '');
				var r = parseInt(hex.substring(0, 2), 16);
				var g = parseInt(hex.substring(2, 4), 16);
				var b = parseInt(hex.substring(4, 6), 16);
				return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity / 100 + ')';
			},
			observeValue(property) {
				var that = this;
				document.getElementById(property).oninput = function() {
					if (property == 'text_color') {
						that.text_color = this.value;
					} else {
						// console.log('背景颜色', this.value);
						that.background_color = this.value;
					}
				};
			},
			saveWaterMarkImages() {
				var image_container = document.getElementById('image_container');
				var image_rect = image_container.getClientRects();

				var image_water_mark = document.getElementById('image_water_mark');
				var water_mark_rect = image_water_mark.getClientRects();
				// console.log('图片范围',image_rect);
				// console.log('水印范围',water_mark_rect);
				var final_scales = this.getFinalRect(image_rect, water_mark_rect);
				var style = window.getComputedStyle(image_container);
				var image_fake_width = parseFloat(style.width.replace('px', ''));
				var font_scale = parseFloat((this.text_font_size / image_fake_width).toFixed(4));
				final_scales['font_scale'] = font_scale;

				for (let image_item of this.images) {
					this.addWaterMarker(image_item, final_scales);
				}
				// console.log('图片长宽比例', font_scale);
			},
			getFinalRect(image_rect, water_mark_rect) {
				var image_bottom = image_rect[0]['bottom'];
				// console.log(image_rect[0]['bottom']);
				var image_top = image_rect[0]['top'];
				var image_height = image_rect[0]['height'];
				var image_left = image_rect[0]['left'];
				var image_width = image_rect[0]['width'];

				var water_mark_bottom = water_mark_rect[0]['bottom'];
				var water_mark_top = water_mark_rect[0]['top'];
				var water_mark_height = water_mark_rect[0]['height'];
				var water_mark_width = water_mark_rect[0]['width'];
				// var water_mark_height=0;
				var water_mark_left = water_mark_rect[0]['left'];

				var final_top = image_top;
				var offset_top = 0;
				if (water_mark_top < image_top) {
					final_top = water_mark_top;
					//如果水印超出了图片，则给图片增加10像素的边距，以此保证水印文字完整显示
					offset_top = 0;
				}

				var final_bottom = water_mark_bottom;
				var offset_bottom = 0;
				if (image_bottom > final_bottom) {
					final_bottom = image_bottom;
					offset_bottom = 0;
				}

				var final_height = final_bottom - final_top + offset_top + offset_bottom;
				var image_start_top_scale = ((image_top - final_top) + offset_top) / final_height;
				// var water_mark_start_top_scale = (water_mark_top - final_top) / final_height + (offset_top +
				// 	water_mark_height) / final_height;
				var water_mark_start_top_scale = (water_mark_top - final_top + water_mark_height / 2) / final_height;
				var final_height_scale = final_height / image_height;
				var water_mark_left_scale = (water_mark_left - image_left) / image_width;
				var water_mark_height_scale = water_mark_height / final_height;
				var water_mark_width_scale = water_mark_width / water_mark_width;
				// var water_mark_width_scale = water_mark_width/ image_width;

				var final_scales = {
					'height_scale': final_height_scale,
					'image_top': image_start_top_scale,
					'water_mark_top': water_mark_start_top_scale,
					'water_mark_left': water_mark_left_scale,
					'water_mark_height': water_mark_height_scale,
					'water_mark_width': water_mark_width_scale,
				}
				// console.log('最终绘制比例',final_scales);
				return final_scales;
			},
			getOsName() {
				var add_font_scale = 0;
				if (navigator.platform.indexOf('Win') !== -1) {
					add_font_scale = 0;
				} else if (navigator.platform.indexOf('Mac') !== -1) {
					// console.log('Mac操作系统');
					add_font_scale = 3 / 4;
				} else if (navigator.platform.indexOf('Linux') !== -1) {
					// console.log('Linux操作系统');
					add_font_scale = 0;
				} else if (navigator.platform.indexOf('iPhone') !== -1 || navigator.platform.indexOf('iPad') !== -1 ||
					navigator.platform.indexOf('iPod') !== -1) {
					// console.log('iOS操作系统');
					add_font_scale = 3 / 4;
				} else if (navigator.platform.indexOf('Android') !== -1) {
					// console.log('Android操作系统');
					add_font_scale = 0;
				} else {
					// console.log('未知操作系统');
					add_font_scale = 0;
				}
				return add_font_scale;
			},
			addWaterMarker(image_item, final_scales) {
				const img = new Image();
				img.setAttribute('crossOrigin', 'Anonymous');
				img.src = image_item['itemImageSrc'];
				img.onerror = function() {
					alert('onload image error');
				};
				var that = this;
				img.onload = function() {
					let canvas = document.createElement('canvas');
					canvas.width = img.width;
					canvas.height = img.height * final_scales['height_scale']; //图像高度可能变高
					// console.log('图片宽度', img.width);
					// console.log('图片高度', img.height);
					let ctx = canvas.getContext('2d');
					ctx.fillStyle = '#fff'; //白色背景
					ctx.fillRect(0, 0, canvas.width, canvas.height);

					var image_start_top = canvas.height * final_scales['image_top'];
					ctx.drawImage(img, 0, image_start_top);

					// 设置填充字号和字体，样式，这里设置字体大小根据canvas的宽度等比缩放，防止大图片生成的水印很小的问题
					var font_scale = final_scales['font_scale'];
					var this_font_size = canvas.width * font_scale;

					var water_mark_top = canvas.height * final_scales['water_mark_top'];
					var water_mark_left = canvas.width * final_scales['water_mark_left'];
					var water_mark_height = canvas.height * final_scales['water_mark_height'];
					// var water_mark_width = canvas.width * final_scales['water_mark_width'];

					//绘制背景
					var this_italic = 'normal';
					if (that.font_style[0] == '1') {
						this_italic = 'oblique';
					}
					// context.font='italic bold 30px 微软雅黑';
					ctx.font = this_italic + ' ' + that.font_weight + ' ' + this_font_size + 'px ' + that.text_font[
						'name'];

					//增加的文字高度
					var add_font_top = 0;

					if (that.save_background[0] == '1') {
						var this_bg_color = that.hexToRgba(that.background_color, that.background_opacity);
						ctx.fillStyle = this_bg_color;

						// const {
						// 	width,height
						// } = ctx.measureText(image_item['water_mark']);

						var metrics = ctx.measureText(image_item['water_mark']);
						var width = ctx.measureText(image_item['water_mark']).width;
						// var height=water_mark_height;
						//所有字在这个字体下的高度
						// var height = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent; 
						// 当前文本字符串在这个字体下用的实际高度
						//如果文本框高度大于文字高度，则文字高度再增加一点
						var font_height = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
						if (font_height < water_mark_height) {
							add_font_top = water_mark_height - font_height;
							// console.log('文字位置增加',add_font_top);
						}

						water_mark_height = font_height;
						// console.log('高度',height);
						//只在文字处绘制背景
						if (that.only_text_background[0] == '1') {
							// ctx.fillRect(water_mark_left, water_mark_top-water_mark_height+this_font_size, water_mark_width, water_mark_height);

							//文字居中的情况
							if (that.horizontal_align == 'left') {
								ctx.fillRect(water_mark_left, water_mark_top - 1 / 2 * (water_mark_height +
										add_font_top), width,
									water_mark_height + add_font_top);
							} else if (that.horizontal_align == 'right') {
								ctx.fillRect(canvas.width - width, water_mark_top - 1 / 2 * (water_mark_height +
										add_font_top),
									width,
									water_mark_height + add_font_top);
							} else {
								ctx.fillRect((canvas.width - width) / 2, water_mark_top - 1 / 2 * (water_mark_height +
										add_font_top), width,
									water_mark_height + add_font_top);
							}

						} else {
							ctx.fillRect(water_mark_left, water_mark_top - 1 / 2 * (water_mark_height + add_font_top),
								canvas
								.width,
								water_mark_height + add_font_top);
						}

					}

					var this_font_color = that.hexToRgba(that.text_color, that.text_opacity);
					ctx.fillStyle = this_font_color;
					ctx.font = this_italic + ' ' + that.font_weight + ' ' + this_font_size + 'px ' + that.text_font[
						'name'];
					// 设置右对齐
					// ctx.textAlign = 'left';
					// console.log('对齐方式',that.horizontal_align);
					ctx.textAlign = that.horizontal_align;
					ctx.textBaseline = "bottom";
					// var add_font_scale=that.getOsName();
					//更新文字高度起点
					// var txt_top = water_mark_top + water_mark_height / 2 - add_font_scale* add_font_top;
					var txt_top = water_mark_top + water_mark_height / 2;
					// var txt_top=water_mark_top;
					//除了左对齐，其他方式都要从中点开始绘制文字
					if (that.horizontal_align == 'left') {
						ctx.fillText(image_item['water_mark'], 0 + water_mark_left, txt_top);
					} else if (that.horizontal_align == 'right') {
						ctx.fillText(image_item['water_mark'], canvas.width + water_mark_left, txt_top);
					} else {
						ctx.fillText(image_item['water_mark'], canvas.width / 2 + water_mark_left, txt_top);
					}
					// 在指定位置绘制文字
					// ctx.fillText('我是水印2', canvas.width - 100, canvas.height - 50)
					// var img_src = canvas.toDataURL('image/jpg');
					// console.log('图片连接',img_src);
					// console.log('图片名称',file_name);
					// 将canvas转成blob文件返回
					//保存文件
					canvas.toBlob(function(blob) {
						//截图完成后的事件，可以增加自己的方法
						saveAs(blob, image_item['file_name']);
						// console.log('图片数据', blob);
						// console.log('图片名称', file_name);
					}, 'image/jpeg', that.compress_scale / 100);
				};
			},
			readConfigFormLocal() {
				if (localStorage.getItem('exif_print_config') != null) {
					var config_his = localStorage.getItem('exif_print_config');
					this.settingConfig(config_his);
				} else {
					this.setDefaultData();
				}
			},
			setDefaultData(){
				this.ReadFileService.getCameraSettings().then(data => {
					// console.log('中文配置', data);
					this.zh_cn_camera_settings = data;
					this.picklistValue = this.zh_cn_camera_settings;
				});
				document.getElementById('text_color').value = this.text_color;
				document.getElementById('background_color').value = this.background_color;
			},
			settingConfig(config_his) {
				var json_his = JSON.parse(config_his);
				if (json_his==null){
					this.setDefaultData();
					return;
				}
				try{
					// console.log('历史配置文件', json_his);
					this.split_char = json_his.split_char;
					this.white_space_number = json_his.white_space_number;
					this.picklistValue = json_his.camera_settings;
					this.use_default = json_his.use_default;
					this.text_top = json_his.text_top;
					this.text_left = json_his.text_left;
					this.text_font_size = json_his.text_font_size;
					this.horizontal_align = json_his.horizontal_align;
					this.vertical_align_start = json_his.vertical_align_start;
					this.text_opacity = json_his.text_opacity;
					this.text_color = json_his.text_color;
					this.background_opacity = json_his.background_opacity;
					this.background_color = json_his.background_color;
					this.text_font = json_his.text_font;
					this.only_text_background = json_his.only_text_background;
					this.save_background = json_his.save_background;
					this.compress_scale = json_his.compress_scale;
					this.font_weight = json_his.font_weight;
					this.font_style = json_his.font_style;
					this.fonts_value = json_his.fonts_value;
					if ('max_decimal_places' in json_his){
						this.max_decimal_places=json_his.max_decimal_places;
					}
					document.getElementById('text_color').value = json_his.text_color;
					document.getElementById('background_color').value = json_his.background_color;
				}catch{
					this.setDefaultData();
				}
				
			},
			saveConfigToLoalItem() {
				this.changeWaterMarkStyle();
				//保存用户设置缓存，转换 proxy_array 到 list
				var camera_settings = JSON.parse(JSON.stringify(this.picklistValue));
				var use_default = JSON.parse(JSON.stringify(this.use_default));
				var save_background = JSON.parse(JSON.stringify(this.save_background));
				var only_text_background = JSON.parse(JSON.stringify(this.only_text_background));
				var data = {
					'split_char': this.split_char,
					'white_space_number': this.white_space_number,
					'camera_settings': camera_settings,
					'use_default': use_default,
					'text_top': this.text_top,
					'text_left': this.text_left,
					'text_font_size': this.text_font_size,
					'horizontal_align': this.horizontal_align,
					'vertical_align_start': this.vertical_align_start,
					'text_color': this.text_color,
					'background_color': this.background_color,
					'text_opacity': this.text_opacity,
					'background_opacity': this.background_opacity,
					'text_font': this.text_font,
					'only_text_background': only_text_background,
					'save_background': save_background,
					'compress_scale': this.compress_scale,
					'font_weight': this.font_weight,
					'font_style': this.font_style,
					'fonts_value': this.fonts_value,
					'max_decimal_places':this.max_decimal_places,
				};
				// for (var key in data){
				// 	if (data[key]==null){
				// 		console.log(key,data[key]);
				// 		return;
				// 	}
				// }
				// console.log('配置文件', data);
				if (camera_settings != null) {
					localStorage.setItem('exif_print_config', JSON.stringify(data));
				}
			},
			changeWaterMarkStyle() {
				var this_bg_color = this.hexToRgba(this.background_color, this.background_opacity);
				//不使用背景颜色
				if (this.save_background[0] != '1') {
					this_bg_color = this.hexToRgba(this.background_color, 0);
				}

				var this_text_color = this.hexToRgba(this.text_color, this.text_opacity);
				//仅文字区域有背景色
				var this_width = '100%';
				if (this.only_text_background[0] == '1') {
					this_width = 'auto';
				}
				var this_italic = 'normal';
				if (this.font_style[0] == '1') {
					this_italic = 'oblique';
				}

				this.water_mark_style = {
					"white-space": "nowrap",
					"width": this_width,
					"background-color": this_bg_color,
					"color": this_text_color,
					"position": "absolute",
					"z-index": 100,
					"margin-top": this.text_top + "px",
					"left": this.text_left + '%',
					"font-family": this.text_font['name'],
					"font-weight": this.font_weight,
					"font-style": this_italic,
					"font-size": this.text_font_size + 'px',
					"text-align": this.horizontal_align,
					"padding": "3px",
				};
				this.water_mark_style_back_up = {
					"white-space": "nowrap",
					"width": "100%",
					"background-color": "rgba(255,255,0,0.4)",
					"color": "rgba(255,255,0,0)",
					"position": "absolute",
					"z-index": 10,
					"margin-top": this.text_top + "px",
					"left": this.text_left + '%',
					"font-family": this.text_font['name'],
					"font-weight": 500,
					"font-size": this.text_font_size + 'px',
					"text-align": this.horizontal_align,
					"padding": "2px",
				};
				// console.log('水印样式',this.water_mark_style);
			},
			//屏幕变化调整样式
			resizeWin() {
				// var center_width = document.documentElement.scrollWidth;
				var windowHeight = document.documentElement.clientHeight;
				this.all_style.height = windowHeight - 70 + 'px';
				this.all_image_style.height = windowHeight - 140 + 'px';
				// console.log('宽度',center_width);
				// if (center_width <= 800) {
				// 	this.all_style.height = "auto";
				// } else {
				// 	var windowHeight = document.documentElement.clientHeight;
				// 	this.all_style.height = windowHeight - 75 + 'px';
				// }
				// this.all_style.height = windowHeight + 'px';
			},
			selectImages() {
				var inputElement = document.getElementById("image_insert");
				inputElement.click();
			},
			//输入修改水印文字
			modifyWaterMark() {
				this.images[this.current_image_index]['water_mark'] = this.current_water_mark;
			},
			getWaterMark(exif_info) {
				// console.log('标签信息',exif_info);
				var count = 0;
				var split_char = this.split_char;
				var distance = Array(this.white_space_number).fill('\xa0').join(''); //变长空格
				var final_str = '';
				var select_group = this.picklistValue[1];
				for (let item of select_group) {
					var this_item_value = null;
					if (item['tag_name'] in exif_info) {
						this_item_value = exif_info[item['tag_name']];
						this_item_value=this.convertibleToNumber(this_item_value);
					}

					//使用默认值
					if (this_item_value == null || this_item_value == '') {
						if (item['default_value'] != '') {
							this_item_value = item['default_value'];
						} else {
							if (this.use_default[0] == '1') {
								//如果不存在默认值，则使用title名称
								this_item_value = item['chinese_name'];
							}

						}
					}

					if (this_item_value) {
						this_item_value = item['prefix'] + ' ' + this_item_value + ' ' + item['suffix'];
						if (count == 0) {
							final_str = this_item_value;
						} else {
							final_str = final_str + distance + split_char + distance + this_item_value;
						}
						count += 1;
					}
				}
				// console.log('最终字符串',final_str);
				return final_str;
			},
			changeImageItem(index) {
				this.current_image_index = index;
				this.current_water_mark = this.images[index]['water_mark'];
				// console.log(index);
				// console.log('改变显示图片', this.images[index]['exif_info']);
			},
			async createImageUrls(file_list) {
				// 限制图片上传大小
				// if (file.size > 1024 * 1024 * 2) {
				//     alert('图片大小不能超过 2MB!');
				//     $("#doc").val("");
				//     return false;
				// }
				this.images = [];
				let URL = window.URL || window.webkitURL;
				var new_images_data = [];
				var count = 0;
				for (let file of file_list) {
					// 通过 file 生成目标 url
					let imgURL = URL.createObjectURL(file);
					var exif_info = await this.pureReadOneExif(file);
					var water_mark = this.getWaterMark(exif_info);
					new_images_data.push({
						"itemImageSrc": imgURL,
						"thumbnailImageSrc": imgURL,
						"alt": 'image ' + count.toString(),
						"title": 'image title ' + count.toString(),
						"exif_info": exif_info,
						"index": count,
						"water_mark": water_mark,
						"file_name": file.name,
					})
					count += 1;
				}
				// console.log('images_data', new_images_data);
				this.images = new_images_data;
			},
			testExifReader() {
				var file_list = ['https://fireflycos.libertynlp.com/firefly-static/login_backimg.jpg'];
				this.pureReadExif(file_list);
			},
			flushAllWaterMark() {
				this.saveConfigToLoalItem(); //先保存所有配置
				// console.log('相机配置文件',this.picklistValue);
				for (let image_index in this.images) {
					var new_water_mark = this.getWaterMark(this.images[image_index]['exif_info']);
					this.images[image_index]['water_mark'] = new_water_mark;
					if (this.current_image_index == image_index) {
						this.current_water_mark = new_water_mark;
					}
				}
			},
			async pureReadOneExif(file) {
				var tags = await ExifReader.load(file);
				// console.log("tags",tags);
				var all_tags = {};
				for (var name in tags) {
					var description = this.getDescription(tags[name]);
					if (description !== undefined) {
						all_tags[name] = description;
					} else {
						all_tags[name] = null;
					}
				}
				// console.log('所有文件标签', all_file_tags)
				// var content = JSON.stringify(all_file_tags);
				// var blob = new Blob([content], {
				// 	type: "application/json;charset=utf-8"
				// });
				// saveAs(blob, "所有文件参数.json");
				return all_tags;
			},
			async pureReadExif(file_list) {
				var all_file_tags = [];
				for (let file of file_list) {
					// console.log('file', file);
					var tags = await ExifReader.load(file);
					// console.log("tags",tags);
					var all_tags = [];
					for (var name in tags) {
						var description = this.getDescription(tags[name]);
						if (description !== undefined) {
							all_tags[name] = description;
						} else {
							all_tags[name] = null;
						}
					}
					all_file_tags.push(all_tags);
				}
				// console.log('所有文件标签', all_file_tags)
				// var content = JSON.stringify(all_file_tags);
				// var blob = new Blob([content], {
				// 	type: "application/json;charset=utf-8"
				// });
				// saveAs(blob, "所有文件参数.json");
				return all_file_tags;
			},
			convertibleToNumber(str) {
				// 尝试将字符串转换为数字
				const num = Number(str);

				// 检查转换后的结果是否是一个有效的数字
				if (!isNaN(num) && isFinite(num)) {
					// 检查是否需要保留小数位数
					const roundedNum = Number(num.toFixed(this.max_decimal_places));
					return roundedNum.toString();
				}
				// console.log('返回小数位结果',num);
				return str;
			},
			getDescription(tag) {
				if (Array.isArray(tag)) {
					return tag.map((item) => item.description).join(', ');
				}
				return tag.description;
			},
			outputFontList() {
				var current_fonts = this.fonts_value;
				var down_str = '';
				for (var i = 0; i < current_fonts.length; i++) {
					var font_name = current_fonts[i]['name'];
					down_str += font_name + '\r\n';
				}

				this.$refs.save_file.contentWindow.saveTxtFile('字体列表.json', down_str);
			},
			//展示成功信息
			showSusscessInfo(msg) {
				this.$toast.add({
					severity: 'success',
					summary: '成功',
					detail: msg,
					life: 3000
				});
			},
			//邮箱错误提示
			showError(msg) {
				this.$toast.add({
					severity: 'error',
					summary: '失败',
					detail: msg,
					life: 5000
				});
			},
			openFile(file_type) {
				var this_e = document.getElementById('choose_file');
				this_e.click();
				this.file_type = file_type;
			},
			setFontsByStr(fonts_str) {
				var fonts_list = fonts_str.split('\r\n');
				// console.log('字体列表',fonts_list);
				var new_fonts_value = [];
				for (var i = 0; i < fonts_list.length; i++) {
					var font_name = fonts_list[i];
					if (font_name.length != 0) {
						new_fonts_value.push({
							'name': font_name,
							'code': font_name,
						})
					}
				}
				this.fonts_value = new_fonts_value;
				//保存到缓存
				this.saveConfigToLoalItem();
			},
			//监听文件打开
			openFileListen() {
				var that = this;
				var this_e = document.getElementById('choose_file');
				// console.log('点击文件选择框');
				this_e.addEventListener('change', function(e) {
					var file = e.currentTarget.files[0]; // js 获取文件对象
					var this_file_name = file.name;
					if (this_file_name.split('.').length != 2) {
						that.showError('只能打开文件名中含一个"."的文件，如test.txt，而不是test.txt.txt');
						return
					}
					// console.log(this_file_name.split('.'));
					if (file) {
						if (file.type != 'text/plain' && file.type != 'application/json') {
							that.$refs.Utils.showError('暂时只支持utf-8编码的txt和json格式文档');
							return;
						}

						const reader = new FileReader();
						reader.readAsText(file); // 将文件读取为文本
						reader.onload = () => { // 文件读取完成后的回调
							if (file.type == 'application/json') {
								that.settingConfig(reader.result);
							} else {
								that.setFontsByStr(reader.result);
								that.saveConfigToLoalItem();
								// console.log('文件名分割',file.name.split('.'));

							}
							var file_message = "读取文件" + file.name + "成功！";
							that.showSusscessInfo(file_message);
						}
						reader.onerror = (e) => {
							// console.log(e, '????XXX')
							alert('文件读取错误,错误信息为：', e)
						}
					}

					this_e.value = '';
				});
			},
		},
		computed: {
			// language() {
			// 	if (this.$appState.language=='en'){
			// 		// this.$refs.pdfjs_viewer_1.contentWindow.setLanguage('en');
			// 		console.log(this);
			// 		return true;
			// 	}else{
			// 		// this.$refs.pdfjs_viewer_1.contentWindow.setLanguage('zh-cn');
			// 		console.log(this);
			// 		return false;
			// 	}
			// },
			language() {
				if (this.$appState.language == 'en') {
					return true;
				} else {
					return false;
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.config-group {
		background-color: #efefef;
		padding: 10px 0px 10px 0px;
		border-radius: 5px;
	}

	.single-item {
		background-color: beige;
		// background-color: yellow;
		font-weight: 700;
		border-radius: 5px;
		padding: 10px;
	}
</style>
